/*** IMPORTS FROM imports-loader ***/


import _ from "underscore";
import "$scripts/mainobj.js";
import "$services/time.js";

var UserServ = function($rootScope, time){
    this._isFromAdminPanel = false;
    this._data = {};
    this.$rootScope = $rootScope;
    this._temporarySession = false;
    this._time = time;

    if(localStorage.user){
        this._data = JSON.parse(localStorage.user);
    }
};

UserServ.$inject = ['$rootScope', 'time'];

UserServ.prototype.types = {
    superadmin: {
        id: 1,
        label: 'Super Admin'
    },
    admin: {
        id: 2,
        label: 'Admin'
    },
    salesman: {
        id: 3,
        label: 'Salesman'
    },
    advertiser: {
        id: 4,
        label: 'Advertiser'
    }
};

UserServ.prototype.getTypeLabelById = function(typeId){
    var res;
    angular.forEach(this.types, function(data){
        if(data.id == typeId){
            res = data.label;
        }
    });

    return res;
};

UserServ.prototype.register = function(data){
    this._data = data;
    localStorage.setItem('user', JSON.stringify(data));
    delete localStorage.adminColumns;
};

UserServ.prototype.registerTemporary = function(data){
    this._data = data;
    this._temporarySession = true;
};

UserServ.prototype.isTemporalSession = function() {
    return this._temporarySession;
};

UserServ.prototype.unregister = function(){
    this._data = {};
    if(!this._temporarySession){
        delete localStorage.user;
        delete localStorage.adminColumns;
    }
};

UserServ.prototype._save = function(){
    if(this._temporarySession){
        return null;
    }

    localStorage.setItem('user', JSON.stringify(this._data));
};

UserServ.prototype.isRegistered = function(){
    return Boolean(_.size(this._data));
};

UserServ.prototype.read = function(field){
    if(!this.isRegistered()){
        this.$rootScope.$broadcast('loginRequired');
        return null;
    }
    if(field) {
        return this._data[field];
    }
    else {
        return this._data;
    }
};

UserServ.prototype.getEmail = function(){
    return this.read('email');
};

UserServ.prototype.getId = function(){
    return this.read('id');
};

UserServ.prototype.getPermLevel = function(){
    return this.read('permission_level');
};

UserServ.prototype.getToken = function(){
    return this.read('token');
};

UserServ.prototype.isSuperAdmin = function(){
    return this.getPermLevel() == this.types.superadmin.id;
};

UserServ.prototype.isAdmin = function(){
    return _.contains([this.types.superadmin.id, this.types.admin.id],
        this.getPermLevel());
};
UserServ.prototype.isAdminOrSalesman = function(){
    return _.contains([
        this.types.superadmin.id,
        this.types.admin.id,
        this.types.salesman.id],
      this.getPermLevel());
};

UserServ.prototype.isAdvertiser = function(){
    return this.getPermLevel() == this.types.advertiser.id;
};

UserServ.prototype.isConversionReportAvalible = function(){
    return this.isAdvertiser() && this.read("show_conversion_report");
};

UserServ.prototype.setAsAdminFromAdminPanel = function () {
  this._isFromAdminPanel = true;
};

UserServ.prototype.isFromAdminPanel = function(){
  return this._isFromAdminPanel;
};

UserServ.prototype.isSalesman = function(){
    return this.getPermLevel() == this.types.salesman.id;
};

UserServ.prototype.setDateRange = function(range){
    if(!this.isRegistered()){
        this.$rootScope.$broadcast('loginRequired');
        return null;
    }
    this._data['date_range'] = range;
    this._save();
};

angular.module('adhesiveApp').service('userServ', UserServ);

