/*** IMPORTS FROM imports-loader ***/


angular.module('adhesiveApp').service(
    'time', ['$filter',
    function($filter){
    this._mockedNowDate = null;
    var filter = $filter('date');

    this.parseStringToDate = function(input) {
      var parts = input.match(/(\d+)/g);
      return new Date(parts[2], parts[0]-1, parts[1], 0, 0, 0, 0, 0);
    };

    this.parseBackendRawDateToDate = function(input) {
      /***
       * Parse backend date with format YYYY-MM-DD to Date object
       */

      var parts = input.match(/(\d\d\d\d)-(\d\d?)-(\d\d?)/);
      var day = parseInt(parts[3]);
      var month = parseInt(parts[2]);
      var year = parseInt(parts[1]);

      return new Date(year, month - 1, day, 0, 0, 0, 0);
    };

    this.getNowDate = function () {
      if (this._mockedNowDate !== null) {
        return new Date(this._mockedNowDate.getTime());
      }
      return new Date();
    };

    this.getUtcNowDate = function () {
      var now = this.getNowDate();
      return this.utcDate(now);
    };

    this.getUtcYesterdayDate = function () {
      var now = this.getUtcNowDate();
      now.setDate(now.getDate() - 1);
      return now;
    };

    this.setNowDate = function(nowDate){
        this._mockedNowDate = nowDate;
    };

    this.setDay = function(date, day){
        var distance = day - date.getDay();
        date.setDate(date.getDate() + distance);
    };

    this.getAmericanDate = function (date) {
        return filter(date, 'MM / dd / yyyy');
    };

    this.getAmericanMonth = function (date) {
        return filter(date, 'MM / yyyy');
    };

    this.getBackendDate = function (date) {
       return filter(date, 'yyyy-MM-dd');
    };

    this.getBackendMonth = function (date) {
      return filter(date, 'yyyy-MM');
    };

    this.utc = function(d){
        return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(),
            d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds(),
            d.getUTCMilliseconds());
    };

    this.utcDate = function(d){
      return new Date(
        d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(),
        0, 0, 0, 0);
    };

    this.isValidDate = function(d) {
      if ( Object.prototype.toString.call(d) !== "[object Date]" ) {
        return false;
      }
      return !isNaN(d.getTime());
    };

    this.checkTimeAndReplace = function(val) {
      var date = this.parseStringToDate(val);
      if (this.isValidDate(date)) {
        return date;
      } else {
        return null;
      }
    };

    this.getDateRange = function(id){
        var now = this.getUtcNowDate();
        var max = new Date(now.getTime());
        var min = new Date(now.getTime());

        switch(id){
            case 'yesterday':
                min.setDate(min.getDate() - 1);
                max.setDate(max.getDate() - 1);
                break;

            case 'last_7_days':
                min.setDate(min.getDate() - 6);
                break;

            case 'this_week':
                min.setHours(0);
                this.setDay(min, 1);
                break;

            case 'last_week':
                min.setHours(0);
                max.setHours(0);
                this.setDay(min, -6);
                this.setDay(max, 0);
                break;

            case 'last_14_days':
                min.setDate(min.getDate() - 13);
                break;

            case 'this_month':
                min.setDate(1);
                break;

            case 'last_30_days':
                min.setDate(min.getDate() - 29);
                break;

            case 'last_month':
                min.setDate(1);
                min.setMonth(min.getMonth() - 1);
                max.setDate(0);
                break;

            case 'last_2_months':
                min.setDate(1);
                min.setMonth(min.getMonth() - 2);
                max.setDate(0);
                break;

            case 'last_3_months':
                min.setDate(1);
                min.setMonth(min.getMonth() - 3);
                max.setDate(0);
                break;
        }

        return {min: min, max: max};
    };

    this.dateToUTCString = function(date){
      return filter(this.utc(date), 'yyyy-MM-dd');
    };

    this.dateToString = function(date){
      return filter(date, 'yyyy-MM-dd');
    };

    this.hourToHour12 = function (hour) {
      hour = hour % 24;
      var hour12 = hour % 12;
      if (hour12 === 0) {  // 00:00 -> 12:00 AM
        hour12 = 12;
      }
      var padded_hour12 = ('0' + hour12).slice(-2);
      if (hour < 12) {
        return padded_hour12 + ':00 AM';
      } else if (hour >= 12) {
        return padded_hour12 + ':00 PM';
      }
    };

}]);

